<template>
	<div class="note" :class="{ disabled: disabled }">
		<div class="title" :class="{ edit: isEdit }">
      <img src="@/assets/icons/note.svg" alt="">
      <span>{{ $t('ai_note')/*備註*/ }}</span>
		</div>
    <textarea
      class="content"
      :class="{ edit: isEdit }"
      :readonly="!isEdit"
      v-model.trim="noteData"
    >
    </textarea>
	</div>
</template>

<script>
export default {
  name: 'Note',
  // props: [ 'value', 'mode', 'disabled' ],
  props: {
    value: {
      type: String,
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    noteData: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.note {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &.disabled {
    pointer-events: none;
    opacity: 0.2;
  }
  .title {
    display: flex;
    align-items: center;
    @include font_style(px2rem(16), px2rem(24), #FFE99F, 700);
    background: #39425D;
    border-radius: 8px 8px 0 0;
    padding: 10px 12px;
    box-sizing: border-box;
    &.edit {
      color: #ffffff;
      font-weight: normal;
      border: 1px solid #9D9D9D;
      img {
        @include filter_FFF;
      }
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      @include filter_FFE99F; 
    }
  }

  .content{
    width: 100%;
    flex: 1;
    padding: 6px 6px 12px 12px;
    overflow-y: scroll;
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    outline: none;
    border: none;
    resize: none;
  }
}

textarea {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #39425D;
  cursor: default;
  &.edit {
    color: #191919;
    background: #ffffff;
    border: 1px solid #9D9D9D;
    outline: none;
  }
}

textarea[read-only] {
  color: #ffffff;
  background: #39425D;
  cursor: default;
}

[contenteditable='false'] {
  color: #ffffff;
  background: #39425D;
}

[contenteditable='true'] {
  color: #191919;
  background: #ffffff;
  outline: none;
}
</style>